<template>
    <div class="flex flex-wrap align-items-center justify-content-center p-fluid">
        <div class="col-12 md:col-5">
            <Toast />
            <!-- 使用條款 -->
            <Dialog position="bottom" header="使用條款" v-model:visible="displayTermsDialog" closable="false"
                dismissableMask="true" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                <p class="line-height-3 m-0">
                <ul id="tos" class="p-1 m-0">
                    <div class="my-2">
                        <p><strong>使用條款TERMS OF USE</strong></p>
                        <p>歡迎使用本公司會員平台。本平台內容係載明您（「<strong>使用者</strong>」或「<strong>您</strong>」）與沐京有限公司（「<strong>本公司</strong>」或「<strong>我們</strong>」）約定之使用條款（「<strong>使用條款</strong>」）。您應依本使用條款存取和使用本公司之平台（下稱「<strong>本平台</strong>」），當您開始存取及/或使用本平台，即表示同意遵守本使用條款並受其約束。請仔細檢閱下列條款，若您不同意本條款，請勿使用本平台。
                        </p>
                        <p><br /><br /><strong>1. 一般條款<br /> </strong> <br /> 1.1
                            本平台內容。本平台提供一個線上會員系統，使公司企業實體或個人存取或使用本平台提供之資訊、資料、文件、文字、圖像、短片、工商名錄、檔案、資料庫、名冊、服務或機制及/或使用本平台（「<strong>內容</strong>」）。
                            <br /><br /> 1.2
                            修訂。本公司得依其決定，於任何時間修訂本使用條款，並將最新版本的使用條款公布於本平台，而無須另行通知會員，使用者有義務隨時審閱最新公布之使用條款。本使用條款非經本公司同意，不得修改或變更。
                        </p>
                        <p><br /><br /><strong>2. 使用條款之接受<br /> </strong> <br />
                            使用者同意本使用條款所載之條款與條件。於存取及/或使用本平台後，使用者即接受並同意受本使用條款之拘束，不論使用者是否實際閱讀或審閱本使用條款。於存取、使用、繼續存取及/或使用本平台，使用者即表示接受並同意受本使用條款之最新版本拘束。如使用者不同意本使用條款最新版本所定之條款與條件，使用者應立即停止使用本平台。
                            <br /><br />
                            本使用條款與隨附及引用納入之本平台隱私權政策、個人資料保護聲明以及本公司不時公布之使用者應遵守條款（如使用者為本平台會員，並含所適用之會員合約），共同構成使用者與本公司間之全部合意，並取代相關於本平台、由本平台或透過本平台所提供之內容、商品或服務，及本使用條款之標的之所有先前所為之協議、承諾或保證。
                        </p>
                        <p><br /><br /><strong>3. 內容提供<br /> </strong> <br /> 3.1
                            提供之內容。本公司對於本平台之內容及提供方式擁有完全決定之權。使用者確認本公司得隨時依裁量移除、修改、升級、暫停、撤銷或終止內容之一部分或全部，而無須事先通知使用者。如使用者未於本公司規定期限內提供本平台服務所需資料或使用本平台服務，則視同放棄，且不得要求任何補償。
                            <br /><br /> 3.2
                            使用限制。本公司有權基於任何原因限制使用者使用一部分或全部內容，包括但不限於修改或移除使用者於本平台張貼之任何資料，或進行事前審查，而無須事先通知使用者。本公司有權依單方裁量，移除其合理認為屬非法、可能致本公司產生責任、違反本使用條款，或本公司認為屬不適當之資料，使用者確認且同意本公司得限制使用者使用內容，且不負任何賠償責任。
                            <br /><br /> 3.3
                            第三人提供之內容。使用者確認並同意，本平台所提供之特定內容可能係由第三人提供（「<strong>第三人內容」</strong>），本公司對於該第三人內容（包括但不限於本平台註冊使用者（「<strong>會員</strong>」）、PayPal、網際威信、各收單銀行、各支付或收付款服務提供機構、各物流業者及/或其他交易平臺所提供之第三人內容），概不負責。使用者並應遵守該第三人所定之條款與條件（包括但不限於各該第三人依外匯管制或其他法令所為之規定）。本公司有權隨時依裁量移除、修改、升級、暫停、撤銷或終止該第三人內容之一部分或全部，而無須事先通知使用者，且本公司對該等措施不負任何責任。
                        </p>
                        <p><br /><br /><strong>4. 使用限制<br /> </strong> <br /> 4.1
                            不得轉售。使用者存取、使用及/或查閱本平台之目的，應限於其個人或內部作業目的，禁止為任何非本使用條款明示准許之目的而使用本平台內容。使用者同意無本公司之事先書面同意，不得為散布、販售或其他商業目的，複製、重製、下載及/或系統性擷取本平台之任何內容。
                            <br /><br /> 4.2
                            違反安全措施。使用者不得違反或試圖違反本平台之安全措施，包括但不限於存取非屬於其所得存取之資料；登入未經授權存取之伺服器或帳戶；試圖探查、檢驗或測試系統或網路之漏洞；試圖破壞未經適當授權之安全或驗證措施；試圖干擾會員服務、主機或網路；或寄發未經收件人許可之電子郵件。使用者若違反系統或網路安全者，可能產生民事或刑事責任。
                            <br /><br /> 4.3
                            違法行為。使用者於使用本平台時，不得（1）違反任何相關法規傳送、散發或儲存資料；（2）侵害他人之專利、商標、著作權、營業秘密，或其他智慧財產權或權利，亦不得違反他人之隱私權、肖像權或其他個人權利；（3）以誹謗、猥褻、威脅、辱罵或憎恨之方式發表任何言論；或（4）從事其他任何違法行為。
                            <br /><br /> 4.4 無締約能力。使用者如無訂立具法律拘束力之合約之能力，不得使用本平台。 <br /><br /> 4.5
                            不實廣告。使用者不得於本平台張貼不實、不正確、誤導性或誹謗性之內容，亦不得張貼詐欺廣告或涉及仿冒品買賣之廣告。 <br /><br /> 4.6
                            垃圾郵件。使用者不得寄發垃圾郵件，包括但不限於未經收件人許可而寄發之任何形式之電子郵件。 <br /><br /> 4.7
                            契約上限制。使用者了解，第4條所定之使用限制及本使用條款之其他相關條款，係屬契約上限制，該等限制可能無法於本平台上以技術手段予以約束。 <br /><br /> 4.6 使用者不得為下列行為：
                        </p>
                        <ol>
                            <li>提供詐欺資料或要約、出售或試圖出售涉及仿冒品或贓物或相關法律禁止銷售及/或行銷之物品、或從事非法活動；</li>
                            <li>侵害或以其他方式鼓勵他人侵害或違反第三人之著作權、專利、商標、營業秘密，或其他專屬權利、隱私權或其他合法權利；</li>
                            <li>違反相關法律、條例或規定、包括但不限於有關進出口管制、不公平競爭、外匯管制、禁止歧視，或與不實廣告有關之法律、條例或規定；</li>
                            <li>提供或推銷猥褻資料、內容或素材；</li>
                            <li>宣揚歧視種族、性別、宗教、國籍、殘障、性向或年齡；</li>
                            <li>提供含損害名譽、誹謗、非法恐嚇或非法騷擾之資料；</li>
                            <li>為涉及複製、重製、利用或濫用本公司各專屬工商名錄、資料庫及名冊或其他資料之行為；</li>
                            <li>計畫破壞本公司及/或內容使用者使用之電腦系統或網路的完整性，或試圖擅自進入該等電腦系統或網路；</li>
                            <li>使用或提供包含破壞、干擾、截斷或濫用任何軟體或硬體系統、資料或個人資料之電腦病毒，或其他破壞性裝置及代碼；</li>
                            <li>提供含有構成未經授權之廣告或騷擾（包括但不限於垃圾郵件）、侵犯他人隱私權，或鼓勵他人為可能構成刑事罪行、發生民事責任或違反法規之行為之資料；</li>
                            <li>提供法令或本使用條款所禁止之商品、服務或內容或其他資料的直接或間接網路連結，或包含該等商品、服務、內容或資料之描述；</li>
                            <li>為屬欺騙其他使用者或本平台其他使用者或有其他非法目的之計畫之一部之行為；及/或</li>
                            <li>直接或間接違反法律。</li>
                        </ol>
                        <p><strong>5. 會員與第三方服務商之交易<br /> </strong> <br /> 5.1
                            使用者知悉並確認，本公司與服務廠商之連接或合作，僅係為提供會員便利之用，並非為本公司就服務廠商之行為及所進行之交易背書。 <br /><br /> 5.2
                            第三方服務商可透過本平台提供會員特定服務，接受該服務之會員必須自行聯繫所需之各項作業以及負擔該服務之義務。如第三方服務商認為可提供會員所需之服務，第三方服務商可透過本公司提供之資料向會員報價。倘會員同意第三方服務商之報價，第三方服務商將向該會員提供服務，該會員將與第三方服務商成立契約關係，並同意支付第三方服務商相關費用，倘日後該會員與第三方服務商間發生相關爭議，會員與第三方服務商皆同意自行解決爭議，與本公司無涉。
                            <br /><br /> 5.3 第三方服務商及會員雙方皆明瞭且同意本公司僅為第三方服務商資訊宣傳之媒介，本公司並未提供任何第三方服務商之服務，亦不擔保第三方服務商之服務品質。
                        </p>
                        <p><strong>6. 智慧財產<br /> </strong> <br />
                            本平台之內容皆屬本公司或其他權利人之智慧財產，且受著作權、商標及其他專屬權利之法律保護，除經本公司或權利人明確以書面同意外，不得下載或以其他方式複製。內容之所有權利、所有權及智慧財產權，均屬本公司所有。未經本公司同意，嚴禁使用，本公司保留所有相關權利。
                        </p>
                        <p><strong>7. 免責聲明與責任限制<br /> </strong> <br /> 7.1
                            無須對使用者活動負責。本公司對任何使用者於本平台上之行為概不負責，亦無須對因該使用者之行為而致使任何人遭受之損害負責。 <br /><br /> 7.2
                            無須對使用者提供之資料負責。本公司對使用者或第三人張貼之任何資料，包括但不限於詐欺、不實、誤導性、不正確、誹謗性、冒犯性或非法資料，概不負責，亦無須對該使用者或該第三人負責，該等資料之損害風險概由各使用者或第三人負責。本公司無義務審閱使用者於本平台張貼之內容。
                            <br /><br /> 7.3
                            無須對本平台之服務、錯誤或中斷負責。本公司無須就下列造成之任何種類的損失、損害、主張/請求、責任或損壞，對使用者或任何第三人負責：（i）本平台、本服務，或自本平台或內容或得獲取之商品發生錯誤、不正確或遺漏，（ii）本平台或功能及其應用無法使用或中斷，（iii）使用者使用本平台，（iv）本平台所提供之服務或所含之內容，（v）因使用或無法使用本平台而遲延或無法履行義務，及/或（vi）因使用者自身行為（包括但不限於未按時繳費、未按時續約或任何未履行使用者義務之情形）致本平台服務之全部或一部撤銷、終止或變更。
                            <br /><br /> 7.4
                            內容提供之免責。本平台及/或透過本平台提供之內容皆係依現況提供，本公司茲明確聲明未提供任何保證，不論明示或暗示性之保證，包括但不限於與產權、品質、條件、性能、準確度、可靠性、適銷性或不侵權有關之保證，並於法律准許之最大範圍內，排除上述保證、聲明、條件及條款。使用者應承擔於本平台或透過本平台使用內容之全部責任與風險。
                            <br /><br /> 7.5
                            未就準確度或能力做出聲明。本公司將盡力確保本平台之最高完整性及準確度，惟於法律准許之最大範圍內，本公司並未就本平台及/或透過本平台提供之資料或商品之有效性、合法性、準確度、品質、可靠性、穩定性或完整性，或供應商銷售商品之能力，或買主購買商品之能力做出任何聲明與保證，亦未聲明與保證能完成任何交易。
                            <br /><br /> 7.6 自行負擔風險。使用者係依其單方裁量下載使用內容或取得資料，應自行承擔相關風險，亦應自行負責因下載或使用上述資料而導致之電腦系統損害或資料損失。
                            <br /><br /> 7.7 無默示保證。除本使用條款明示之保證外，本公司對於本平台內容未提供任何保證。 <br /><br /> 7.8
                            其他平台之連結。本平台包含與第三人平台之連結，該等連結僅為方便使用者使用而提供，並非本公司為該第三人平台內容背書，故使用者連結至該等平台時，應自行為其風險負責。 <br /><br />
                            7.9
                            提供政府機關資訊。本公司有權與政府、法院、檢調機關、執法機關、私人調查公司及/或受損之第三人充分合作，調查任何可疑之刑事、民事或行政之違法行為。本公司亦得應政府、法院、檢調機關、執法機關、私人調查公司或受損第三人之要求，或因傳票或其他法律行動，而揭露使用者身分、聯絡資料或其他任何資訊，且對其所生之損害或結果概不負責。使用者同意不就上述之揭露行為，對本公司提起行動、主張及/或請求（含訴訟請求）。
                            <br /><br /> 7.10
                            無衍生性損害。本公司無須負責之損害賠償，包括但不限於下列：特殊、直接、間接、附隨或衍生性損害，包括但不限於因使用本平台、任何人無法使用本平台，或因性能失效、錯誤、遺漏、中斷、瑕疵、作業或傳送遲延、電腦病毒、系統故障，或第三人未經授權擅自存取會員資料或私人資料，導致之利益、商譽、使用、資料或其他無形損失，或營業中斷等損害，不論本公司事先是否經告知可能會發生該等損害，亦同。
                            <br /><br /> 7.11
                            責任限制。在不影響上開規定並符合相關法律規定之情形下，本公司與其員工、代理、董事、經理人、關係企業、代表、繼受人、資料或文件之第三人供應商，或任何代表本公司之人，針對與內容使用、本平台或本平台提供之資料、商品及文件有關之請求，應對各使用者負責的全部累積責任，應以新臺幣20,000元為限，且該金額得取代使用者可對本公司提出之其他所有救濟，亦不排除要求使用者提出實際損害之證明。
                        </p>
                        <p><br /><strong>8. 隱私權政策<br /> </strong> <br />
                            本平台之隱私權政策屬本使用條款之一部，本平台目前之隱私權政策內容得於本平台多數頁面下方的「Privacy Policy」連結存取，會員亦得點選<a
                                href="http://info.taiwantrade.com/contract/privacy-policy">此處</a>，查閱本公司之隱私權政策。由於網路之發展，安全功能與隱私權政策亦可能隨時改變，因此，本公司有權隨時修改或變更其隱私權政策，使用者應定期查閱隱私權政策，以獲得更動的任何最新訊息。
                        </p>
                        <p><br /><strong>9. 使用者賠償<br /> </strong> <br />
                            使用者若違反本使用條款（包括但不限於使用者所為之聲明與保證）和本使用條款隨附及引用納入之文件，或透過本平台使用本服務，或侵害其他人之權利（包括但不限於提供之任何商品、內容、資料，侵害第三人之專利、著作權、商標或其他智慧財產權），導致本公司、其員工、代理、董事、經理人、關係企業、代表、繼受人、資料或文件之第三人供應商，或任何代表本公司之人遭受任何責任、請求、主張、損失、支出（包括但不限於訴訟及律師費）和損害，則使用者應賠償因此所生之一切損失。本公司保留防禦及控制上述事項之專有與獨佔權利，且使用者不因此免除賠償之責。
                        </p>
                        <p><br /><strong>10. 通知<br /> </strong> <br />
                            除另有約定外，提供予本公司以及與本使用條款有關之法律通知，均應以電郵或書面信函送達本公司。本公司有權依使用者於申請時，提供予本公司的地址或電郵，將法律通知送交使用者。以電郵方式寄發之通知，除發文方接獲之電郵地址係屬無效地址之通知外，應於發出電郵後24小時視為送達，若以郵寄方式寄發之通知，應以該通知投遞後3天視為送達。
                        </p>
                        <p><br /><strong>12. 其他規定<br /> </strong> <br /> 12.1
                            準據法及管轄法院。本使用條款之準據法為中華民國法律（不適用其選法規則）。雙方因本使用條款所生之ㄧ切爭議，以中華民國台中地方法院為第一審之管轄法院。 <br /><br /> 12.2
                            無代理關係。本使用條款並未於使用者與本公司之間，產生或擬產生任何代理、合夥、合資、僱傭或加盟關係。 <br /><br /> 12.3
                            分割性。本使用條款任一條款或部分被任何管轄法院視為無法執行或無效時，該條款或部分應自本使用條款中刪除，而本使用條款之其餘條款或部分應保持完全效力。該無法執行或無效之條款或部分應於法律允許之範圍內變更俾儘可能達成該條款或部分欲達成之目的。
                            <br /><br /> 12.4 標題。本使用條款條文標題僅為方便參考而提供，不得用以定義、限制、解釋或說明該條文之範圍。 <br /><br /> 12.5
                            不棄權。本公司在使用者違反本使用條款時，放棄或未執行權利或採取行動，不得視為放棄其得就使用者之其他違約行為採取行動之權利。 <br /><br /> 12.6
                            轉讓。本公司有權將本使用條款轉讓予任何人或實體，但使用者不得將本使用條款轉讓予任何人或實體，但經本公司同意者，不在此限。
                        </p>
                        <p> 12.7 語言。如本使用條款之其他翻譯版本與繁體中文版本有任何歧異，以繁體中文版本為準。</p>
                        <p><strong>個人資料保護聲明 Notice of Personal Information Protection </strong></p>
                        <p>沐京有限公司（以下統稱「本公司」）尊重並致力保護您的個人隱私及個人資料，訂有「個人資料保護聲明」，並依個人資料保護法第8條規定，告知以下事項：</p>
                        <p><br /><br />1. 蒐集目的<strong><br /></strong> <br />
                            協助個人職涯發展事宜；協助廠商人力資源及管理；政令宣導；教育或訓練行政；圖書出版品管理；廣告或商業行為管理；行銷；契約、類似契約或其他法律關係事務；商業與技術資訊；資(通)訊服務；資(通)訊與資料庫管理；資通安全與管理；調查、統計與研究分析；以及非公務機關依法定義務所進行個人資料之蒐集處理及利用等。
                        </p>
                        <p><br /><br />2. 個人資料類別<strong><br /></strong> <strong><br /></strong>
                            識別類、社會情況、及/或其他各類依蒐集目的所必要之個人資料等。 </p>
                        <p><strong><br /></strong> <br />3. 個人資料處理、利用之期間、地區、對象及方式 <br /> (一)
                            期間：個人資料蒐集之特定目的存續期間、或依相關法令或契約約定之保存年限、或本公司因執行業務所必須之保存期間（較法令或契約約定期間長者），或另經您同意之較長期間。 <br /> (二)
                            地區：包括中華民國、未受中央目的事業主管機關限制之國際傳輸個人資料之接收者所在地、個人資料利用對象之所在地，以及本公司業務往來機構之所在地。 <br /> (三)
                            對象：您的資料可能會提供給本公司的代理人、簽約人、提供線上付款認證或其他相關服務之服務供應商、及合作夥伴（例如PayPal、網際威信、各收單銀行、各支付或收付款服務提供機構）、未受中央目的事業主管機關限制之國際傳輸個人資料之接收者、其他與本公司有業務往來之機構、法院、及/或政府主管機關。
                            <br /> (四) 方式：以自動化機器或其他非自動化之方式為蒐集、處理、利用與國際傳輸。
                        </p>
                        <p><br /><br />4. 依個人資料保護法規定得行使之權利與方式<strong><br /></strong> <strong><br /></strong>
                            就您的個人資料，依個人資料保護法規定，您有權：(1) 查詢或請求閱覽、及請求製給複製本，本公司得酌收必要成本費用；(2) 請求補充或更正，惟依法您應為適當之釋明；(3)
                            請求停止蒐集、處理或利用、及請求刪除，但因本公司執行職務或業務所必須者，得依法不依您的請求為之；及(4)如利用您的個人資料行銷時，您得表示拒絕接受行銷。 <br /><br />5.
                            不提供個人資料所致權益之影響<strong><br /></strong> <strong><br /></strong> 如不同意提供資料，將無法接受本公司所提供之服務。 </p>
                        <p><br /><strong><br />6. </strong>已確認所提供之個人資料真實且正確；如事後有異動情形，將即檢具相關證明文件送交本公司辦理更正。</p>
                    </div>
                </ul>
                </p>
                <template #footer>
                    <Button label="關閉" icon="pi pi-times" class="p-button-secondary" v-on:click="closeTermsDialog()" />
                </template>
            </Dialog>

            <!-- 顯示錯誤 -->
            <Dialog position="bottom" header="請修正輸入資料" v-model:visible="displayInvalidPopup" closable="false"
                dismissableMask="true" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                <p class="line-height-3 m-0">
                <ul id="example-1" class="p-1 m-0">
                    <div v-for="invalid in invalidSummary" :key="invalid" class="mt-2">
                        - {{ invalid }}
                    </div>
                </ul>
                </p>
                <template #footer>
                </template>
            </Dialog>
            <!-- 主頁面 -->
            <div class="card">
                <div class="mb-4 mt-4 w-full flex justify-content-center">
                    <span class="text-3xl font-bold">會員註冊</span>
                </div>
                <div class="grid formgrid">
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <span class="p-input-icon-left p-float-label">
                            <i class="pi pi-user" />
                            <InputText type="text" id="name" v-model="nameValue"
                                v-bind:class="{ 'p-invalid': isInvalidName }" />
                            <label for="name">姓名</label>
                        </span>
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <span class="p-input-icon-left p-float-label">
                            <i class="pi pi-phone" />
                            <InputText type="text" id="phone" v-model="phoneValue"
                                v-bind:class="{ 'p-invalid': isInvalidPhone }" />
                            <label for="phone">手機號碼</label>
                        </span>
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <span class="p-input-icon-left p-float-label">
                            <i class="pi pi-envelope" />
                            <InputText type="text" id="email" v-model="emailValue"
                                v-bind:class="{ 'p-invalid': isInvalidMail }" />
                            <label for="companyid">電子郵件信箱(即時收到商品資訊)</label>
                        </span>
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <span class="p-input-icon-left p-float-label">
                            <i class="pi pi-lock" />
                            <InputText type="password" id="password" v-model="passwordValue"
                                v-bind:class="{ 'p-invalid': isInvalidPassword }" />
                            <label for="comment">欲設定之密碼 (至少包含2個英文字及4個數字)</label>
                        </span>
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <span class="p-input-icon-left p-float-label">
                            <i class="pi pi-lock" />
                            <InputText type="password" id="password" v-model="passwordValue2"
                                v-bind:class="{ 'p-invalid': isInvalidPassword }" />
                            <label for="comment">再次確認密碼</label>
                        </span>
                    </div>
                    <div v-on:click="openDatesDialog()" v-if="displayDateTimeButton"
                        class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <Button v-bind:label="selectDateTimeButtonLabel" class="p-button-purpply p-button-outlined mr-2" />
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <div class="field-checkbox mb-0">
                            <Checkbox id="checkOption1" name="option" value="agree" v-model="checkboxValue" />
                            <label for="checkOption1">我同意 <span class="text-indigo-400 cursor-pointer"
                                    v-on:click="openTermsDialog()">使用條款、資料政策</span></label>
                        </div>
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <Button v-on:click="submitForm" label="開始簡訊認證" class="p-button-secondary p-button mr-2" />
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        * 如您未收到驗證簡訊，請確認是否有使用「拒收企業簡訊」功能之相關阻擋廣告訊息類之APP，謝謝
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            /* values */
            nameValue: "",
            addressValue: "",
            phoneValue: "",
            emailValue: "",
            passwordValue: "",
            passwordValue2: "",
            checkboxValue: "",

            // flags
            selectedToday: false,
            isInvalidName: false,
            isInvalidAddress: false,
            isInvalidPhone: false,
            isInvalidMail: false,
            isInvalidPassword: false,
            invalidSummary: "",

            // displays
            displayInvalidPopup: false,
            displayTermsDialog: false,
        };
    },
    mounted() {
    },
    beforeUnmount() { },
    created() { },
    methods: {
        submitForm() {
            this.isInvalidName = false;
            this.isInvalidPhone = false;
            this.isInvalidMail = false;
            this.isInvalidPassword = false;

            // check name
            var wrongFmt = false;

            this.invalidSummary = [];

            if (this.nameValue.trim().length == 0) {
                this.invalidSummary.push("姓名不得為空白");
                this.isInvalidName = true;
                wrongFmt = true;
            }

            var phone_check = new RegExp('^09[0-9]{8}$');
            if (this.phoneValue.trim().length <= 0 || phone_check.test(this.phoneValue.trim()) == false) {
                this.invalidSummary.push("手機號碼格式錯誤");
                this.isInvalidPhone = true;
                wrongFmt = true;
            }

            var email_check = new RegExp('(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$)|(^$)');
            if (this.emailValue.trim().length <= 0 || email_check.test(this.emailValue.trim()) == false) {
                this.invalidSummary.push("電子郵件信箱錯誤");
                this.isInvalidMail = true;
                wrongFmt = true;
            }

            var password_check = /^(?=.*[0-9].*[0-9].*[0-9].*[0-9])(?=.*[a-zA-Z].*[a-zA-Z]).{6,}/;
            if (password_check.test(this.passwordValue) == false) {
                this.invalidSummary.push("密碼至少包含2個英文及4個數字");
                this.isInvalidPassword = true;
                wrongFmt = true;
            }

            if (this.passwordValue != this.passwordValue2) {
                this.invalidSummary.push("兩次輸入的密碼不一致");
                this.isInvalidPassword = true;
                wrongFmt = true;
            }


            if (this.checkboxValue != "agree") {
                this.invalidSummary.push("請同意使用條款，謝謝您");
                wrongFmt = true;
            }

            if (wrongFmt) {
                this.popupInvalidCheck();
                return;
            }
            this.sendSMS();
        },
        popupInvalidCheck() {
            this.displayInvalidPopup = true;
        },
        sendSMS() {
            axios.defaults.withCredentials = true;
            axios(process.env.VUE_APP_API_BASE_URL + "/sendSMS", {
                method: "GET",
                withCredentials: true,
                params: {
                    b: localStorage.brand,
                    s: "",
                    name: this.nameValue,
                    phone: this.phoneValue,
                    email: this.emailValue,
                    password: this.passwordValue
                },
            })
                .then((response) => {
                    console.log(response.data);
                    var status = response.data;
                    if (status == "success") {
                        this.$toast.add({ severity: 'success', summary: '簡訊已寄送', detail: '請留意簡訊收件匣', life: 3000 });
                        // Redirect back to login
                        setTimeout(() => {
                            this.$router.push({
                                path: "/", // Entrance
                                name: "login",
                            });
                        }, "3000")
                    } else
                        this.$toast.add({ severity: 'info', summary: '簡訊', detail: status, life: 3000 });
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        openTermsDialog() {
            this.displayTermsDialog = true;
        },
        closeTermsDialog() {
            this.displayTermsDialog = false;
        },
    },
    computed: {
    },
};
</script>

<style scoped lang="scss">
.p-text-purpply {
    color: var(--gray-700);
}

.btn {
    border-radius: 10px;
    border-bottom: 0px;
    display: inline-block;
    padding: 10px;
    position: relative;
    text-align: center;
    transition: background 600ms ease, color 600ms ease;
}

input[type="radio"].toggle {
    display: none;

    &+label {
        cursor: pointer;
        min-width: 120px;
        background-color: var(--purpply-unselected-color);

        &:hover {
            background: none;
            color: var(--purpply-primary-color);
        }

        &:after {
            background-color: var(--purpply-primary-color);
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
        }
    }

    &.toggle-left+label {
        border-right: 0;

        &:after {
            left: 100%;
        }
    }

    &.toggle-right+label {
        margin-left: 0px;

        &:after {
            left: -100%;
        }
    }

    &:checked+label {
        cursor: default;
        color: #fff;
        background-color: var(--purpply-primary-color);
        transition: color 200ms;

        &:after {
            left: 0;
        }
    }
}</style>
